import React, { Component } from "react";
import Switch from "react-switch";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  TextField,
  MenuItem,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete"; // Ensure this import is correct

import LoadingDialog from "./dialog/LoadingDialog";
import AdvSearchDialog from "./dialog/AdvSearchDialog";
import ErrorDialog from "./dialog/ErrorDialog";
import AdminDialog from "./dialog/AdminDialog";
import TableSearch from "./native/TableSearch";
import Pagination from "./native/Pagination";

import gif_img from "./resources/loading.gif";
import img_error from "./resources/error.png";
import img_setting from "./resources/settings.png";
import img_edit from "./resources/baseline_edit.png";
import moment from "moment";

var request = require("../utilities/NetworkRequest");
var str_util = require("../utilities/StringUtil");
var cache_util = require("../utilities/CacheUtil");
var view_util = require("../utilities/ViewUtil");

const data = [
  {
    show_data: true,
    wholesaler_id: "TP9999",
    pcode: "PDU009467",
    barcode: "9990112100104",
    name: "1001 TEMBUS 12 BTG",
    imgtiny_url:
      "https://gpapi.tokopintarmayora.com/upload/root_products/PDU009467.jpeg",
    cat_a: "CA0013",
    cat_a_desc: "Rokok",
    cat_b: "PR0256",
    cat_b_desc: "OTHERS",
    idx_pagination: 1,
    is_exist: false,
    uom_a: "BTG",
  },
  {
    show_data: true,
    wholesaler_id: "TP9999",
    pcode: "PDU001381",
    barcode: "8997018920135",
    name: "169 KRETEK 12 BTG",
    imgtiny_url:
      "https://gpapi.tokopintarmayora.com/upload/root_products/PDU001381.jpeg",
    cat_a: "CA0013",
    cat_a_desc: "Rokok",
    cat_b: "PR0477",
    cat_b_desc: "PR JATI KAWI",
    idx_pagination: 1,
    is_exist: false,
    uom_a: "BTG",
  },
  {
    show_data: true,
    wholesaler_id: "TP9999",
    pcode: "PDU011434",
    barcode: "1564984",
    name: "169 KRETEK 16 BTG",
    imgtiny_url:
      "https://gpapi.tokopintarmayora.com/upload/root_products/PDU011434.png",
    cat_a: "CA0013",
    cat_a_desc: "Rokok",
    cat_b: "PR0477",
    cat_b_desc: "PR JATI KAWI",
    idx_pagination: 1,
    is_exist: false,
    uom_a: "PAC",
  },
  {
    show_data: true,
    wholesaler_id: "TP9999",
    pcode: "PDU013199",
    barcode: "5764222",
    name: "17.17 BAKERI ROTI MANIS",
    imgtiny_url:
      "https://gpapi.tokopintarmayora.com/upload/root_products/PDU013199.png",
    cat_a: "CA0002",
    cat_a_desc: "Makanan Ringan",
    cat_b: "PR0256",
    cat_b_desc: "OTHERS",
    idx_pagination: 1,
    is_exist: false,
    uom_a: "PCS",
  },
  {
    show_data: true,
    wholesaler_id: "TP9999",
    pcode: "PDU006287",
    barcode: "8342412434545",
    name: "286 DJI PEK LAK 12 BTG",
    imgtiny_url:
      "https://gpapi.tokopintarmayora.com/upload/root_products/PDU006287.jpeg",
    cat_a: "CA0013",
    cat_a_desc: "Rokok",
    cat_b: "PR0107",
    cat_b_desc: "MERAPI GUNUNG LESTARI ",
    idx_pagination: 1,
    is_exist: false,
    uom_a: "BOX",
  },
];

export default class VoucherPromoHO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_main_loading: false,
      is_loading: false,
      is_error: false,
      error_message: "",
      body_error: {
        is_show: false,
        message: "",
      },
      dialog_advance: {
        show: false,
        body: null,
      },
      show_admin_dialog: {
        show: false,
        body: null,
      },
      admin_role: null,
      user_information: null,
      header_left: [],
      header_search: {},
      table_header: {},
      table_data: [],
      pagination: {},
      pagination_array: [],
      show_add_promo_dialog: false,
      new_promo: {
        id_voucher: "",
        deduction: "",
        nama_voucher: "",
        type_voucher: "",
        cust_provider: "",
        start_date: "",
        last_date: "",
        voucher_condition: "",
        daily_limit: "",
        weekly_limit: "",
        wholesaler_reward: "",
        purchase_limit: "",
        minimum_limit: "",
        product_search: "",
        product_list: [],
        selected_products: [], // Change to array to store multiple selected products
        qty_multiple: "", // Add qty_multiple field
        qty_divider: "", // Add qty_divider field
        group_voucher: "", // Add group_voucher field
        principal_search: "", // Add principal_search field
        principal_list: [], // Add principal_list field
        selected_principal: null, // Add selected_principal field
      },
    };
  }

  componentDidMount() {
    this.refreshData(false, false);
  }

  refreshData(table_only, is_filter) {
    this.setState(
      {
        is_main_loading: true,
      },
      () => {
        try {
          let decoded_input = this.state.user_information;
          if (decoded_input === null) {
            decoded_input = cache_util.onGetCacheData(cache_util.CACHE_INFO)
              .result.user_information;
            decoded_input.length_data = 50;
          }
          decoded_input.idx = 0;
          if (!is_filter) {
            if (decoded_input.hasOwnProperty("filter")) {
              delete decoded_input.filter;
            }
          }
          request.onRequestData(
            1,
            "/show/voucher_promo",
            decoded_input,
            (res_data) => {
              let temp_pagination_array = [];
              if (res_data.code === 1) {
                temp_pagination_array.push(res_data.result.res_data.pagination);
                if (table_only) {
                  this.setState({
                    is_main_loading: false,
                    is_error: false,
                    table_data: res_data.result.res_data.arrays,
                    pagination: res_data.result.res_data.pagination,
                    pagination_array: temp_pagination_array,
                  });
                } else {
                  this.setState({
                    is_main_loading: false,
                    is_error: false,
                    dialog_advance: {
                      show: false,
                      body: res_data.result.filterV2,
                    },
                    admin_role: res_data.result.admin_role,
                    user_information: decoded_input,
                    header_left: res_data.result.header.left,
                    header_search: res_data.result.header.search,
                    table_header: res_data.result.res_data.header,
                    table_data: res_data.result.res_data.arrays,
                    pagination: res_data.result.res_data.pagination,
                    pagination_array: temp_pagination_array,
                  });
                }
              } else {
                this.setState({
                  is_main_loading: false,
                  is_error: true,
                  error_message: res_data.message,
                });
              }
            }
          );
        } catch (e) {
          this.setState({
            is_main_loading: false,
            is_error: true,
            error_message: e.message,
          });
        }
      }
    );
  }

  onCloseAdmin(action, data) {
    let temp_dialog = this.state.show_admin_dialog;
    temp_dialog.show = !temp_dialog.show;
    temp_dialog.body = null;
    this.setState(
      {
        is_loading: action === "next" ? true : false,
        show_admin_dialog: temp_dialog,
      },
      () => {
        if (action === "next") {
          data.privilege_id = 3;
          data.selected_privilege = data.admin_role.selected.key;
          data.phone = str_util.parseHandphone(data.phone);
          if (data.is_update) {
            this.updateAdmin(data);
          } else {
            this.createVoucher(data);
          }
        }
      }
    );
  }

  createVoucher(body) {
    body.user_id = this.state.user_information.user_id;
    body.is_admin = true;

    let parameters = {
      id_voucher: body.id_voucher,
      cust_provider: body.cust_provider,
      deduction: body.deduction,
      description: body.nama_voucher,
      type: body.type_voucher,
      start_date: body.start_date,
      last_date: body.last_date,
      voucher_condition: body.voucher_condition,
      daily_limit: body.daily_limit,
      weekly_limit: body.weekly_limit,
      wholesaler_reward: 0,
      purchase_limit: body.purchase_limit,
      minimum_limit: body.minimum_limit,
      is_active: false,
      group_voucher: body.group_voucher,
      mapp_product: body.selected_products,
      principal: body.selected_principal,
    };

    console.log("parameters: ", parameters);

    // request.onRequestData(1, "/post/voucher_promo", parameters, (res_data) => {
    //   if (res_data.code === 1) {
    //     this.setState(
    //       {
    //         is_loading: false,
    //       },
    //       () => {
    //         this.refreshData(body.type_action);
    //       }
    //     );
    //   } else {
    //     this.setState({
    //       is_loading: false,
    //       body_error: {
    //         is_show: true,
    //         message: res_data.message,
    //       },
    //     });
    //   }
    // });
  }

  updateAdmin(body) {
    body.user_id = this.state.user_information.user_id;
    body.is_admin = true;
    request.onRequestData(1, "/update/admin", body, (res_data) => {
      if (res_data.code === 1) {
        this.setState(
          {
            is_loading: false,
          },
          () => {
            this.refreshData(body.type_action);
          }
        );
      } else {
        this.setState({
          is_loading: false,
          body_error: {
            is_show: true,
            message: res_data.message,
          },
        });
      }
    });
  }

  updateStatus(checked, data) {
    console.log(data);

    let body = {
      is_admin: true,
      is_active: checked,
      id_voucher: data.id_voucher,
    };
    request.onRequestData(1, "/update/voucher_promo", body, (res_data) => {
      if (res_data.code === 1) {
        this.setState(
          {
            is_loading: false,
          },
          () => {
            this.refreshData(false, false);
          }
        );
      } else {
        this.setState({
          is_loading: false,
          body_error: {
            is_show: true,
            message: res_data.message,
          },
        });
      }
    });
  }

  showAdminDialog(body_dialog) {
    let data = this.state.admin_role;
    let temp_admin_dialog = this.state.show_admin_dialog;
    temp_admin_dialog.show = !temp_admin_dialog.show;
    if (body_dialog === null) {
      body_dialog = {
        wholesaler_id: "",
        phone: "",
        access_code: "",
        is_update: false,
      };
    } else {
      for (let i = 0; i < Object.keys(data.data).length; i++) {
        if (data.data[i].key === body_dialog.privilege_id) {
          data.selected = data.data[i];
          break;
        }
      }
      body_dialog.is_update = true;
    }
    body_dialog.admin_role = data;
    body_dialog.super_admin = true;
    temp_admin_dialog.body = JSON.stringify(body_dialog);
    this.setState({ show_admin_dialog: temp_admin_dialog });
  }

  onLeftHeaderActionClicked(position) {
    switch (this.state.header_left[position].key) {
      case "refresh":
        this.refreshData(true, false);
        break;
      case "add":
        this.showAdminDialog(null);
        break;
      default:
        break;
    }
  }

  handleAddPromoDialogOpen = () => {
    this.setState({ show_add_promo_dialog: true });
  };

  handleAddPromoDialogClose = () => {
    console.log("new_promo: ==> ", this.state.new_promo);

    this.setState({ show_add_promo_dialog: false });
  };

  handleSavePromo = () => {
    this.createVoucher(this.state.new_promo);
    this.handleAddPromoDialogClose();
  };

  handleInputChange = (field, value, pcode = null) => {
    if (pcode) {
      this.setState((prevState) => ({
        new_promo: {
          ...prevState.new_promo,
          selected_products: prevState.new_promo.selected_products.map(
            (product) =>
              product.pcode === pcode ? { ...product, [field]: value } : product
          ),
        },
      }));
    } else {
      if (
        field !== "nama_voucher" &&
        field !== "type_voucher" &&
        field !== "voucher_condition" &&
        field !== "start_date" &&
        field !== "last_date" &&
        field !== "cust_provider" &&
        field !== "product_search" &&
        field !== "group_voucher" &&
        field !== "principal_search"
      ) {
        const rawValue = value.replace(/,/g, "");
        if (!isNaN(rawValue)) {
          this.setState((prevState) => ({
            new_promo: {
              ...prevState.new_promo,
              [field]: rawValue,
            },
          }));
        }
      } else {
        console.log("handleInputChange: ", field, value, pcode);
        this.setState((prevState) => ({
          new_promo: {
            ...prevState.new_promo,
            [field]: value,
          },
        }));
      }
    }
  };

  formatNumber = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  handleProductSearch = () => {
    const pcode = this.state.new_promo.product_search;
    request.onRequestData(
      1,
      "/show/get_product_pcode",
      { pcode },
      (res_data) => {
        if (res_data.code === 1) {
          this.setState((prevState) => ({
            new_promo: {
              ...prevState.new_promo,
              product_list: res_data.result,
            },
          }));
        } else {
          this.setState({
            body_error: {
              is_show: true,
              message: res_data.message,
            },
          });
        }
      }
    );
  };

  handlePrincipalSearch = () => {
    const pcode = this.state.new_promo.principal_search;
    request.onRequestData(
      1,
      "/show/get_principal",
      { pcode },
      (res_data) => {
        if (res_data.code === 1) {
          this.setState((prevState) => ({
            new_promo: {
              ...prevState.new_promo,
              principal_list: res_data.result,
            },
          }));
        } else {
          this.setState({
            body_error: {
              is_show: true,
              message: res_data.message,
            },
          });
        }
      }
    );
  };

  handleProductSelect = (event, value) => {
    if (value !== null) {
      const isProductSelected = this.state.new_promo.selected_products.some(
        (product) => product.pcode === value.pcode
      );

      if (isProductSelected) {
        alert("Product sudah dimasukkan");
      } else {
        this.setState((prevState) => ({
          new_promo: {
            ...prevState.new_promo,
            selected_products: [
              ...prevState.new_promo.selected_products,
              { ...value, qty_multiple: 1, qty_divider: 1 },
            ],
            product_list: prevState.new_promo.product_list.filter(
              (product) => product.pcode !== value.pcode
            ),
          },
        }));
      }
    }
  };

  handlePrincipalSelect = (event, value) => {
    this.setState((prevState) => ({
      new_promo: {
        ...prevState.new_promo,
        selected_principal: value,
      },
    }));
  };

  handleProductDelete = (pcode) => {
    this.setState((prevState) => ({
      new_promo: {
        ...prevState.new_promo,
        selected_products: prevState.new_promo.selected_products.filter(
          (product) => product.pcode !== pcode
        ),
      },
    }));
  };

  initLeftHeader(data) {
    let view = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      view.push(
        <div
          onClick={this.onLeftHeaderActionClicked.bind(this, i)}
          key={data[i].key}
          className="container_image"
        >
          <img
            className="mini_image_b"
            src={require(data[i].image + "")}
            alt=""
          />
        </div>
      );
    }
    // view.push(
    //   <Button
    //     variant="contained"
    //     color="primary"
    //     onClick={this.handleAddPromoDialogOpen}
    //     key="add_promo"
    //   >
    //     Add Promo/Voucher
    //   </Button>
    // );
    return view;
  }

  onCheckboxClicked(is_header, position) {
    let temp_table_header = this.state.table_header;
    let temp_table_data = this.state.table_data;
    if (is_header) {
      temp_table_header.is_selected = !temp_table_header.is_selected;
      for (let i = 0; i < Object.keys(temp_table_data).length; i++) {
        temp_table_data[i].is_selected = temp_table_header.is_selected;
      }
      this.setState({
        table_header: temp_table_header,
        table_data: temp_table_data,
      });
    } else {
      temp_table_data[position].is_selected = !temp_table_data[position]
        .is_selected;
      if (
        !temp_table_data[position].is_selected &&
        temp_table_header.is_selected
      ) {
        temp_table_header.is_selected = !temp_table_header.is_selected;
        this.setState({
          table_header: temp_table_header,
          table_data: temp_table_data,
        });
      } else {
        this.setState({
          table_data: temp_table_data,
        });
      }
    }
  }

  initDataTable(data) {
    let view = [],
      i,
      i_found = 0;
    for (i = 0; i < Object.keys(data).length; i++) {
      const data_index = data[i];
      if (data[i].show_data) {
        i_found++;
        view.push(
          <tr key={i}>
            <td className="whole_padding default_text_normal_gray">
              {data[i].id_voucher}
            </td>
            <td className="whole_padding default_text_normal_gray">
              {data[i].description}
            </td>
            <td className="whole_padding default_text_normal_gray">
              {moment(data[i].start_date).format("DD-MM-YYYY HH:mm")}
            </td>
            <td className="whole_padding default_text_normal_gray">
              {moment(data[i].last_date).format("DD-MM-YYYY HH:mm")}
            </td>
            <td className="whole_padding default_text_normal_gray">
              {/* {data[i].deduction} */}
              {str_util.formatSeparator(data[i].deduction)}
            </td>
            <td className="whole_padding default_text_normal_gray">
              {data[i].voucher_condition === 1
                ? "Tidak Multiple"
                : data[i].voucher_condition === 2
                ? "Multiple / Kelipatan"
                : "Presentase dari deduction"}
            </td>
            <td className="whole_padding default_text_normal_gray">
              {str_util.formatSeparator(data[i].daily_limit)}
            </td>
            <td className="whole_padding default_text_normal_gray">
              {str_util.formatSeparator(data[i].weekly_limit)}
            </td>
            <td className="whole_padding default_text_normal_gray">
              {str_util.formatSeparator(data[i].minimum_limit)}
            </td>
            <td className="whole_padding default_text_normal_gray">
              {data[i].is_active}
              <Switch
                onChange={(checked) => {
                  const isConfirmed = window.confirm(
                    `Apakah Anda yakin ingin ${
                      data_index.is_active ? "menonaktifkan" : "mengaktifkan"
                    } item ini?`
                  );
                  if (isConfirmed) {
                    this.updateStatus(checked, data_index);
                  } else {
                    console.log("Canceled");
                  }
                }}
                checked={data[i].is_active}
                className="react-switch"
                height={20}
                width={40}
                onColor="#43987b"
              />
            </td>
            <td className="whole_padding_d">
              <div className="wrapper_product_action">
                <div
                  //   onClick={this.showAdminDialog.bind(this, data[i])}
                  style={{ width: 80, backgroundColor: "#a9a9a9" }}
                  className="custom_padding_e label_search_container "
                >
                  <img className="mini_image_d" src={img_edit} alt="" />
                  <div className="normal_padding_left_c default_text_normal_white">
                    Coming Soon
                  </div>
                </div>
              </div>
            </td>
          </tr>
        );
      }
    }
    if (i_found < 1) {
      view.push(view_util.getZeroRow(9));
    }
    return view;
  }

  initTableView(data) {
    return (
      <table className="table_container_retail">
        <thead>
          <tr>
            <th className="whole_padding default_text_normal_gray main_column">
              {data.id_voucher}
            </th>
            <th className="whole_padding default_text_normal_gray">
              {data.description}
            </th>
            <th className="whole_padding default_text_normal_gray main_column">
              {data.start_date}
            </th>
            <th className="whole_padding default_text_normal_gray">
              {data.last_date}
            </th>
            <th className="whole_padding default_text_normal_gray">
              {data.deduction}
            </th>
            <th className="whole_padding default_text_normal_gray">
              {data.voucher_condition}
            </th>
            <th className="whole_padding default_text_normal_gray">
              {data.daily_limit}
            </th>
            <th className="whole_padding default_text_normal_gray">
              {data.weekly_limit}
            </th>
            <th className="whole_padding default_text_normal_gray">
              {data.minimum_limit}
            </th>
            <th className="whole_padding default_text_normal_gray">
              {data.is_active}
            </th>
            <th className="whole_padding default_text_normal_gray">Action</th>
          </tr>
        </thead>
        <tbody>{this.initDataTable(this.state.table_data)}</tbody>
      </table>
    );
  }

  requestVoucherPromoAdmin(body, temp_pagination_array) {
    this.setState(
      {
        is_loading: true,
      },
      () => {
        request.onRequestData(1, "/show/voucher_promo", body, (res_data) => {
          if (res_data.code === 1) {
            if (temp_pagination_array == null) {
              temp_pagination_array = this.state.pagination_array;
              temp_pagination_array.push(res_data.result.res_data.pagination);
            }
            this.setState({
              is_loading: false,
              table_data: res_data.result.res_data.arrays,
              pagination: res_data.result.res_data.pagination,
              pagination_array: temp_pagination_array,
            });
          } else {
            this.setState({
              is_loading: false,
              body_error: {
                is_show: true,
                message: res_data.message,
              },
            });
          }
        });
      }
    );
  }

  initSearchHeader() {
    return (
      <div className="search_root_container">
        {/* <TableSearch header={this.state.header_search} table={this.state.table_data} onTableUpdate={(data_table) => this.setState({ table_data : data_table })} /> */}
        <div
          onClick={() =>
            this.showDialogAdvance("open", this.state.dialog_advance.body)
          }
          className="container_image_b"
        >
          <img className="mini_image_b" src={img_setting} alt="" />
        </div>
      </div>
    );
  }

  showDialogAdvance(action, body) {
    let temp = this.state.dialog_advance;
    temp.show = !temp.show;
    switch (action) {
      case "next":
        let temp_info = this.state.user_information;
        temp_info.filter = JSON.stringify(body);
        this.setState(
          {
            user_information: temp_info,
            dialog_advance: temp,
          },
          () => {
            this.refreshData(true, true);
          }
        );
        break;
      default:
        this.setState({ dialog_advance: temp });
        break;
    }
  }

  onPaginationClicked(index, data) {
    let temp_body = this.state.user_information;
    if (index === 9999 && data === null) {
      temp_body.idx = this.state.pagination.index;
    } else {
      temp_body.idx = this.state.pagination_array[index].index_before;
    }
    this.requestVoucherPromoAdmin(temp_body, data);
  }

  showErrorDialog(body) {
    if (body.is_show) {
      return (
        <ErrorDialog
          body_error={body}
          onCloseDialog={() =>
            this.setState({ body_error: { is_show: false, message: "" } })
          }
        />
      );
    } else {
      return null;
    }
  }

  render() {
    if (this.state.is_main_loading) {
      return (
        <div className="loading_container">
          <img className="big_image" src={gif_img} alt="" />
        </div>
      );
    } else {
      if (!this.state.is_error) {
        return (
          <div className="delivery_root">
            <div className="header_container">
              <div className="flex_left">
                {this.initLeftHeader(this.state.header_left)}
              </div>
              <div className="flex_right">{this.initSearchHeader()}</div>
            </div>
            {this.initTableView(this.state.table_header)}
            <Pagination
              pagination={this.state.pagination}
              pagination_array={this.state.pagination_array}
              onChangePage={(index, page_array) =>
                this.onPaginationClicked(index, page_array)
              }
            />

            {this.state.dialog_advance.show ? (
              <AdvSearchDialog
                body={this.state.dialog_advance.body}
                onCloseListener={(action, body) => {
                  this.showDialogAdvance(action, body);
                }}
              />
            ) : null}
            {this.state.show_admin_dialog.show ? (
              <AdminDialog
                data={this.state.show_admin_dialog.body}
                onCloseListener={(action, data) => {
                  this.onCloseAdmin(action, data);
                }}
              />
            ) : null}
            {this.showErrorDialog(this.state.body_error)}
            <LoadingDialog showDialog={this.state.is_loading} />
            <Dialog
              open={this.state.show_add_promo_dialog}
              onClose={this.handleAddPromoDialogClose}
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle>
                Add Promo/Voucher
                <IconButton
                  aria-label="close"
                  onClick={this.handleAddPromoDialogClose}
                  style={{ position: "absolute", right: 8, top: 8 }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                <TextField
                  variant="outlined"
                  label="ID Voucher"
                  type="number"
                  fullWidth
                  margin="normal"
                  value={this.state.new_promo.id_voucher}
                  onChange={(e) =>
                    this.handleInputChange("id_voucher", e.target.value)
                  }
                />
                <TextField
                  variant="outlined"
                  label="Nama Voucher"
                  type="text"
                  fullWidth
                  margin="normal"
                  value={this.state.new_promo.nama_voucher}
                  onChange={(e) =>
                    this.handleInputChange("nama_voucher", e.target.value)
                  }
                />
                <TextField
                  variant="outlined"
                  label="Type Voucher"
                  select
                  fullWidth
                  margin="normal"
                  value={this.state.new_promo.type_voucher}
                  onChange={(e) =>
                    this.handleInputChange("type_voucher", e.target.value)
                  }
                >
                  <MenuItem value="product_id">Product ID</MenuItem>
                  <MenuItem value="id_bundle">ID Bundle</MenuItem>
                  <MenuItem value="principal_id">Principal ID</MenuItem>
                </TextField>
                {this.state.new_promo.type_voucher === "product_id" && (
                  <div>
                    <TextField
                      variant="outlined"
                      label="Product Search"
                      type="text"
                      fullWidth
                      margin="normal"
                      value={this.state.new_promo.product_search}
                      onChange={(e) =>
                        this.handleInputChange("product_search", e.target.value)
                      }
                      InputProps={{
                        endAdornment: (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleProductSearch}
                          >
                            Search
                          </Button>
                        ),
                      }}
                    />
                    <Autocomplete
                      options={this.state.new_promo.product_list}
                      getOptionLabel={(option) => option.name}
                      onChange={this.handleProductSelect}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Product"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          InputProps={{ ...params.InputProps, readOnly: true }}
                        />
                      )}
                    />
                    {this.state.new_promo.selected_products.length > 0 && (
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Product Code</TableCell>
                              <TableCell>Product Name</TableCell>
                              <TableCell>Qty Multiple</TableCell>
                              <TableCell>Qty Divider</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.new_promo.selected_products.map(
                              (product) => (
                                <TableRow key={product.pcode}>
                                  <TableCell>{product.pcode}</TableCell>
                                  <TableCell>{product.name}</TableCell>
                                  <TableCell>
                                    <TextField
                                      disabled
                                      variant="outlined"
                                      type="number"
                                      value={product.qty_multiple}
                                      onChange={(e) =>
                                        this.handleInputChange(
                                          "qty_multiple",
                                          e.target.value,
                                          product.pcode
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      disabled
                                      variant="outlined"
                                      type="number"
                                      value={product.qty_divider}
                                      onChange={(e) =>
                                        this.handleInputChange(
                                          "qty_divider",
                                          e.target.value,
                                          product.pcode
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      onClick={() =>
                                        this.handleProductDelete(product.pcode)
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </div>
                )}
                {this.state.new_promo.type_voucher === "principal_id" && (
                  <div>
                    <TextField
                      variant="outlined"
                      label="Principal Search"
                      type="text"
                      fullWidth
                      margin="normal"
                      value={this.state.new_promo.principal_search}
                      onChange={(e) =>
                        this.handleInputChange(
                          "principal_search",
                          e.target.value
                        )
                      }
                      InputProps={{
                        endAdornment: (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handlePrincipalSearch}
                          >
                            Search
                          </Button>
                        ),
                      }}
                    />
                    <Autocomplete
                      options={this.state.new_promo.principal_list}
                      getOptionLabel={(option) => option.name}
                      onChange={this.handlePrincipalSelect}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Principal"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          InputProps={{ ...params.InputProps, readOnly: true }}
                        />
                      )}
                    />
                  </div>
                )}
                <TextField
                  variant="outlined"
                  label="Customer Provider"
                  type="text"
                  fullWidth
                  margin="normal"
                  value={this.state.new_promo.cust_provider}
                  onChange={(e) =>
                    this.handleInputChange("cust_provider", e.target.value)
                  }
                />
                <TextField
                  variant="outlined"
                  label="Start Date"
                  type="date"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.new_promo.start_date}
                  onChange={(e) =>
                    this.handleInputChange("start_date", e.target.value)
                  }
                />
                <TextField
                  variant="outlined"
                  label="Last Date"
                  type="date"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.new_promo.last_date}
                  onChange={(e) =>
                    this.handleInputChange("last_date", e.target.value)
                  }
                />
                <TextField
                  variant="outlined"
                  label="Voucher Condition"
                  select
                  fullWidth
                  margin="normal"
                  value={this.state.new_promo.voucher_condition}
                  onChange={(e) =>
                    this.handleInputChange("voucher_condition", e.target.value)
                  }
                >
                  <MenuItem value="1">Tidak Multiple</MenuItem>
                  <MenuItem value="2">Multiple / Kelipatan</MenuItem>
                  <MenuItem value="3">Presentase dari deduction</MenuItem>
                </TextField>
                <TextField
                  variant="outlined"
                  label="Deduction"
                  type="text"
                  fullWidth
                  margin="normal"
                  value={this.formatNumber(this.state.new_promo.deduction)}
                  onChange={(e) =>
                    this.handleInputChange("deduction", e.target.value)
                  }
                  InputProps={{
                    endAdornment:
                      this.state.new_promo.voucher_condition === "3" ? (
                        <InputAdornment position="end">%</InputAdornment>
                      ) : null,
                  }}
                />
                <TextField
                  variant="outlined"
                  label="Daily Limit"
                  type="text"
                  fullWidth
                  margin="normal"
                  value={this.formatNumber(this.state.new_promo.daily_limit)}
                  onChange={(e) =>
                    this.handleInputChange("daily_limit", e.target.value)
                  }
                />
                <TextField
                  variant="outlined"
                  label="Weekly Limit"
                  type="text"
                  fullWidth
                  margin="normal"
                  value={this.formatNumber(this.state.new_promo.weekly_limit)}
                  onChange={(e) =>
                    this.handleInputChange("weekly_limit", e.target.value)
                  }
                />
                <TextField
                  variant="outlined"
                  label="Purchase Limit"
                  type="text"
                  fullWidth
                  margin="normal"
                  value={this.formatNumber(this.state.new_promo.purchase_limit)}
                  onChange={(e) =>
                    this.handleInputChange("purchase_limit", e.target.value)
                  }
                />
                <TextField
                  variant="outlined"
                  label="Minimum Limit"
                  type="text"
                  fullWidth
                  margin="normal"
                  value={this.formatNumber(this.state.new_promo.minimum_limit)}
                  onChange={(e) =>
                    this.handleInputChange("minimum_limit", e.target.value)
                  }
                />
                <TextField
                  variant="outlined"
                  label="Wholesaler Reward"
                  type="text"
                  fullWidth
                  margin="normal"
                  value={this.formatNumber(
                    this.state.new_promo.wholesaler_reward
                  )}
                  onChange={(e) =>
                    this.handleInputChange("wholesaler_reward", e.target.value)
                  }
                />
                <Autocomplete
                  options={this.state.table_data}
                  getOptionLabel={(option) =>
                    `${option.description} - ${option.group_voucher}`
                  }
                  onChange={(event, value) => {
                    console.log("value: ", value);

                    this.handleInputChange(
                      "group_voucher",
                      value ? value.group_voucher : ""
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Group Voucher"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  )}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleAddPromoDialogClose}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button onClick={this.handleSavePromo} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      } else {
        return (
          <div className="loading_container">
            <img className="big_image_b" src={img_error} alt="" />
            <div className="whole_padding_c default_text_big_gray">
              {this.state.error_message}
            </div>
          </div>
        );
      }
    }
  }
}
